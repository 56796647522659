import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Copytask1 } from '../../../components/images/support/tasks/Copytask1.generated';
import { Copytask2 } from '../../../components/images/support/tasks/Copytask2.generated';
import { Copytask3 } from '../../../components/images/support/tasks/Copytask3.generated';
import { Copytask4 } from '../../../components/images/support/tasks/Copytask4.generated';
import { Copytask5 } from '../../../components/images/support/tasks/Copytask5.generated';
import { Copytask6 } from '../../../components/images/support/tasks/Copytask6.generated';
import { Copytask7 } from '../../../components/images/support/tasks/Copytask7.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "copying-tasks-"
    }}>{`Copying Tasks 📋✔`}</h1>
    <p>{`Form tasks can be copied between different workflows. Follow along to see how.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Workflows`}</strong>{` menu. Click into the existing Workflow that has the form task you wish to copy.`}</li>
    </ol>

    <Copytask1 mdxType="Copytask1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the form task you wish to copy then click `}<strong parentName="li">{`Copy form`}</strong>{`.`}</li>
    </ol>

    <Copytask2 mdxType="Copytask2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Navigate back to the `}<strong parentName="li">{`Workflows`}</strong>{` menu. Click into the Workflow that you want to paste the form task into.`}</li>
    </ol>

    <Copytask3 mdxType="Copytask3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Edit`}</strong>{` button, then click the menu button and then click `}<strong parentName="li">{`Paste form`}</strong>{`.`}</li>
    </ol>

    <Copytask4 mdxType="Copytask4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Paste`}</strong>{` button.`}</li>
    </ol>

    <Copytask5 mdxType="Copytask5" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Add form`}</strong>{` button on the task.`}</li>
    </ol>

    <Copytask6 mdxType="Copytask6" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Save`}</strong>{` button on the workflow.`}</li>
    </ol>

    <Copytask7 mdxType="Copytask7" />
    <h1 {...{
      "id": "great-job-you-have-copied-the-form-task-"
    }}>{`Great job you have copied the form task 🎉.`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      